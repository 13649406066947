import React, { useState, useRef, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import productsData from '../public/products-data.json';
import '../public/featuredProducts.css';

const Card = ({ children }) => (
  <div className="card" itemScope itemType="http://schema.org/Product">
    {children}
  </div>
);

const CardContent = ({ children }) => (
  <div className="card-content">
    {children}
  </div>
);

const FeaturedProducts = ({ products = [] }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const validProducts = Array.isArray(products) ? products : [];

  useEffect(() => {
    updateArrowVisibility();
    window.addEventListener('resize', updateArrowVisibility);
    return () => window.removeEventListener('resize', updateArrowVisibility);
  }, []);

  const updateArrowVisibility = () => {
    if (containerRef.current) {
      setShowLeftArrow(containerRef.current.scrollLeft > 0);
      setShowRightArrow(
        containerRef.current.scrollLeft < containerRef.current.scrollWidth - containerRef.current.offsetWidth
      );
    }
  };

  const scroll = (direction) => {
    const container = containerRef.current;
    if (container) {
      const scrollAmount = direction === 'left' ? -container.offsetWidth : container.offsetWidth;
      const targetScroll = container.scrollLeft + scrollAmount;
      const itemWidth = container.children[0].offsetWidth;
      const nearestItem = Math.round(targetScroll / itemWidth) * itemWidth;
      
      container.scrollTo({
        left: nearestItem,
        behavior: 'smooth'
      });
      
      setTimeout(updateArrowVisibility, 300);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  if (validProducts.length === 0) {
    return (
      <section className="section featured-products py-8">
        <h2 className="text-2xl font-bold text-center mb-8">Équipements Phares</h2>
        <p className="text-center text-gray-600">Aucun produit disponible pour le moment.</p>
      </section>
    );
  }

  return (
    <section className="section featured-products py-8">
      <h2 className="text-2xl font-bold text-center mb-8">Équipements Phares</h2>
      
      <div className="product-slider relative">
        {showLeftArrow && (
          <button 
            onClick={() => scroll('left')}
            className="arrow left-arrow"
            aria-label="Previous products"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
        )}

        <div className="products-container flex overflow-x-auto" ref={containerRef}>
          {validProducts.map((product) => (
            <Card key={product?.asin || Math.random()}>
                <CardContent>
                  <meta itemProp="name" content={product?.title || 'Produit sans titre'} />
                  <meta itemProp="description" content={truncateText(product?.title || 'Description non disponible', 100)} />
                  <div className="aspect-square overflow-hidden rounded-lg mb-2 h-40"> 
                    <img
                      src={product?.mainImage || '/api/placeholder/200/200'} 
                      alt={product?.title || 'Product image'}
                      className="w-full h-full object-contain"
                      itemProp="image"
                    />
                  </div>
                  
                  <h3 className="font-semibold text-base mb-1" itemProp="name">
                    {truncateText(product?.title || 'Produit sans titre', 50)}
                  </h3>
                  
                  <div className="flex items-center gap-1 mb-1"> 
                    <div className="flex items-center">
                      {[...Array(5)].map((_, i) => ( 
                        <span 
                          key={i} 
                          className={`text-sm ${i < Math.floor(product?.rating?.stars || 0) ? 'text-yellow-400' : 'text-gray-300'}`} 
                        >
                          ★ 
                        </span>
                      ))}
                    </div>
                    <span className="text-xs text-gray-600">
                      ({product?.rating?.count || 0}) 
                    </span>
                  </div>

                  <div className="text-lg font-bold mb-2" itemProp="offers" itemScope itemType="http://schema.org/Offer">
                    <span itemProp="price">{product?.price?.displayPrice || 'Prix non disponible'}</span>
                    <meta itemProp="priceCurrency" content="EUR" />
                  </div>

                  {product?.url && ( 
                    <a 
                      href={product.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block w-full bg-[#FF9900] hover:bg-[#FF9900]/90 text-white text-center py-1 rounded-lg text-sm font-medium amazon-btn" 
                      itemProp="url"
                    >
                      Voir sur Amazon
                    </a>
                  )}
                </CardContent>
            </Card>
          ))}
        </div>

        {showRightArrow && (
          <button 
            onClick={() => scroll('right')}
            className="arrow right-arrow"
            aria-label="Next products"
          >
            <ChevronRight className="w-6 h-6" />
          </button>
        )}
      </div>
    </section>
  );
};

document.addEventListener('DOMContentLoaded', () => {
  const container = document.querySelector('.featured-products');

  if (container) {
    const root = createRoot(container);
    
    try {
      if (!Array.isArray(productsData) || productsData.length === 0) {
        console.warn('Aucun produit trouvé dans products-data.json');
      }
      
      root.render(<FeaturedProducts products={productsData} />);
      
      const event = new CustomEvent('featuredProductsLoaded');
      document.dispatchEvent(event);
      
    } catch (error) {
      console.error('Erreur détaillée lors du chargement des produits:', error);
      console.error('Message d\'erreur:', error.message);
      console.error('Stack trace:', error.stack);
      root.render(
        <FeaturedProducts 
          products={[]} 
          error="Erreur lors du chargement des produits"
        />
      );
    }
  }
});
