[
  {
    "asin": "B07W5JKPJX",
    "title": "Logitech G PRO X SUPERLIGHT Souris Gaming sans Fil, Capteur Gaming HERO 25K, 25 600 PPP, Ultra-Léger avec 63g, 5 Boutons Programmables, Batterie Longue Durée, PC/Mac - Noire",
    "description": [
      "Ce Logitech G Conçue en collaboration avec les meilleurs joueurs professionnels du monde, la souris de jeu eSports est conçue pour vous aider à gagner et constitue l'aboutissement de notre quête des meilleures performances",
      "Ultralégère de moins de 63 g, avec un design ultra minimaliste qui pèse près de 25 % de moins que la souris de jeu sans fil G PRO standard",
      "Alimentée par LIGHTSPEED, la PRO X SUPERLIGHT est la souris PRO la plus rapide et la plus fiable jamais conçue",
      "Contrôle incroyablement précis, rapide et constant grâce au capteur Hero, conçu dès le départ par Logitech G -ingénieurs pour les meilleures performances de jeu possibles",
      "La souris a de grands pieds en PTFE sans additifs qui garantissent qu'elle Logitech G la souris de jeu se déplace facilement pour une connexion fluide au jeu",
      "Logitech G La souris de jeu sans fil PRO X SUPERLIGHT est disponible en noir et blanc",
      "Pour des performances sans fil optimales, utilisez la souris dans un rayon de réception de 20 cm et à plus de 2 mètres des sources d'interférences 2,4 GHz (telles que les routeurs Wi-Fi)",
      "Pour installer le ruban adhésif en option, nettoyez d'abord la surface de la souris avec le chiffon de préparation de surface fourni pour éliminer l'huile ou la poussière. Ensuite, alignez soigneusement le grip tape avec les surfaces de la souris",
      "Le récepteur USB peut être rangé dans la souris en retirant le couvercle du PowerPlay. Cela permet d'éviter de perdre le récepteur lorsque vous utilisez la souris avec le Logitech G Système de recharge sans fil POWERPLAY",
      "Taux de rapport USB: 1 000 Hz (1 ms)"
    ],
    "mainImage": "https://m.media-amazon.com/images/I/31Ub3hpjHWL._SL500_.jpg",
    "variantImages": [
      "https://m.media-amazon.com/images/I/41+-c7gsONL._SL500_.jpg",
      "https://m.media-amazon.com/images/I/41pQ4aOE7TL._SL500_.jpg",
      "https://m.media-amazon.com/images/I/41orLJXHkeL._SL500_.jpg",
      "https://m.media-amazon.com/images/I/41kFI3tAH4L._SL500_.jpg",
      "https://m.media-amazon.com/images/I/51xtcbLqVCL._SL500_.jpg",
      "https://m.media-amazon.com/images/I/41fF4qaMaZL._SL500_.jpg",
      "https://m.media-amazon.com/images/I/414AoYRmIWL._SL500_.jpg",
      "https://m.media-amazon.com/images/I/41n9JFYZgcL._SL500_.jpg",
      "https://m.media-amazon.com/images/I/41FYXmetpaL._SL500_.jpg"
    ],
    "price": {
      "amount": 78.99,
      "currency": "EUR",
      "displayPrice": "78,99 €"
    },
    "rating": {
      "stars": 0,
      "count": 0
    },
    "url": "https://www.amazon.fr/dp/B07W5JKPJX?tag=esportgear0f-21&linkCode=ogi&th=1&psc=1"
  },
  {
    "asin": "B08176SM7C",
    "title": "SteelSeries Rival 3 - USB Souris Gaming - Capteur Optique Truemove Core de 8500 Cpi - 6 Boutons Programmables - Boutons en Gâchettes Scindées, Noir",
    "description": [
      "Matériaux hyper durables - Conçu en polymère ultra-résistant pour une longue durée de vie Construction de base : Plastique ABS",
      "Switchs mécaniques à 60 millions de clics – Précision du premier au dernier clic",
      "Capteur optique de gaming TrueMove Core – Véritable suivi 1:1, 8 500 CPI, 300 IPS, 35G d'accélération",
      "Construction confortable et ergonomique – Design ergonomique garantissant d'intenses sessions de gaming",
      "Éclairage brillant Prism – L'éclairage repensé offre 3 zones de 16,8 millions de superbes couleurs ultra-nettes"
    ],
    "mainImage": "https://m.media-amazon.com/images/I/21nUt0nB0-L._SL500_.jpg",
    "variantImages": [
      "https://m.media-amazon.com/images/I/21+wUY4khuL._SL500_.jpg",
      "https://m.media-amazon.com/images/I/41ChQIPLIfL._SL500_.jpg",
      "https://m.media-amazon.com/images/I/31sHJAUt8bL._SL500_.jpg",
      "https://m.media-amazon.com/images/I/31a1RQl9I3L._SL500_.jpg",
      "https://m.media-amazon.com/images/I/412tBmdC-XL._SL500_.jpg",
      "https://m.media-amazon.com/images/I/318jW6mRBqL._SL500_.jpg",
      "https://m.media-amazon.com/images/I/21ResRrjIoL._SL500_.jpg"
    ],
    "price": {
      "amount": 29.95,
      "currency": "EUR",
      "displayPrice": "29,95 €"
    },
    "rating": {
      "stars": 0,
      "count": 0
    },
    "url": "https://www.amazon.fr/dp/B08176SM7C?tag=esportgear0f-21&linkCode=ogi&th=1&psc=1"
  },
  {
    "asin": "B0D1KCF7L2",
    "title": "SteelSeries Arctis Nova 5P Casque Gaming PS5 Sans Fil - Haut-parleurs magnétiques néodyme - 100+ profils audio - Batterie 60h - 2,4GHz ou BT - Micro ClearCast Gen 2.X - Pour PS4/5, PC, Switch, Mobile",
    "description": [
      "PLUS DE 100 PRÉRÉGLAGES AUDIO - Préréglages audio sur mesure pour des jeux comme GTA V, FIFA, Call of Duty, avec l’appli Arctis Nova 5",
      "BATTERIE DE 60H - Jouez plus longtemps avec la batterie nouvelle génération : 8h/jour toute la semaine. 6h d’utilisation en 15 mn avec la charge rapide USB-C",
      "QUICK-SWITCH SANS FIL - Basculez entre jeu 2,4GHz et Bluetooth 5.3 d’un geste pour prendre un appel ou écouter un média. Bip de notification pour être informé lors du jeu",
      "AUDIO HAUTE FIDÉLITÉ - En métal terrestre rare, les haut-parleurs magnétiques en néodyme créent un son ultra-détaillé d’aigus clairs, de médiums précis et de basses profondes",
      "MICRO NOUVELLE GÉNÉRATION - Notre micro rétractable ClearCast 2.X offre 2x la clarté avec un chipset haute bande passante (audio 32KHz/16Bit)",
      "TRANS-PLATEFORME - Passez entre PlayStation, PC, Switch, Meta Quest, téléphones ou tablettes avec un dongle USB-C compact",
      "CONTRÔLES SUR L’ÉCOUTEUR - Tous les boutons nécessaires sont sur le casque : alimentation, volume et Quick-Switch pour passer au Bluetooth"
    ],
    "mainImage": "https://m.media-amazon.com/images/I/41o62mf3MjL._SL500_.jpg",
    "variantImages": [
      "https://m.media-amazon.com/images/I/51h9YPDQyWL._SL500_.jpg",
      "https://m.media-amazon.com/images/I/415jcgD1gFL._SL500_.jpg",
      "https://m.media-amazon.com/images/I/41yE2U5eHBL._SL500_.jpg",
      "https://m.media-amazon.com/images/I/414Ayhgj+4L._SL500_.jpg",
      "https://m.media-amazon.com/images/I/419tx3iw9pL._SL500_.jpg",
      "https://m.media-amazon.com/images/I/41LIf9nXXkL._SL500_.jpg",
      "https://m.media-amazon.com/images/I/31pkXTeAIpL._SL500_.jpg",
      "https://m.media-amazon.com/images/I/31+PLW2m46L._SL500_.jpg",
      "https://m.media-amazon.com/images/I/31FSOBfEvAL._SL500_.jpg",
      "https://m.media-amazon.com/images/I/41QDQ-bnQ+L._SL500_.jpg",
      "https://m.media-amazon.com/images/I/51U-CXD7FYL._SL500_.jpg",
      "https://m.media-amazon.com/images/I/41aN11lCMTL._SL500_.jpg",
      "https://m.media-amazon.com/images/I/41cQmYN2s7L._SL500_.jpg"
    ],
    "price": {
      "amount": 114.99,
      "currency": "EUR",
      "displayPrice": "114,99 €"
    },
    "rating": {
      "stars": 0,
      "count": 0
    },
    "url": "https://www.amazon.fr/dp/B0D1KCF7L2?tag=esportgear0f-21&linkCode=ogi&th=1&psc=1"
  },
  {
    "asin": "B00SAYCXWG",
    "title": "HyperX Cloud II – Casque Gaming avec Micro pour PC/PS4/Mac, Rouge",
    "description": [
      "Son Surround 7.1 virtuel. SPL nominal: 98 ± 3 dB. Réponse en fréquence : 15Hz-25,000 Hz",
      "Mousse à mémoire de forme sur l'arceau",
      "Optimisé pour le jeu vidéo pro",
      "Ecouteurs fermés assurent une réduction passive du bruit",
      "Type de connexion : Simple 3.5 mm",
      "Impédance nominale : 60 Ω par système",
      "Compatibilité: PC, PS5 , PS4 , Xbox Series X|S, Xbox One, commutateur Marche, Mobile"
    ],
    "mainImage": "https://m.media-amazon.com/images/I/41nK0pwsbFL._SL500_.jpg",
    "variantImages": [
      "https://m.media-amazon.com/images/I/41+9IoPT1KL._SL500_.jpg",
      "https://m.media-amazon.com/images/I/41nD1XKwO4L._SL500_.jpg",
      "https://m.media-amazon.com/images/I/31KKwHXdl+L._SL500_.jpg",
      "https://m.media-amazon.com/images/I/41WucRYn-wL._SL500_.jpg"
    ],
    "price": {
      "amount": 69.99,
      "currency": "EUR",
      "displayPrice": "69,99 €"
    },
    "rating": {
      "stars": 0,
      "count": 0
    },
    "url": "https://www.amazon.fr/dp/B00SAYCXWG?tag=esportgear0f-21&linkCode=ogi&th=1&psc=1"
  },
  {
    "asin": "B08HJ2VCB7",
    "title": "BenQ ZOWIE XL2546K FHD 1080p Écran Gaming (24,5 Pouces, 240 Hz, 0.5ms, DyAc+, XL Setting to Share, S switch, Shielding Hood)",
    "description": [
      "La technologie Dynamic Accuracy + rend moins floues les actions de jeux dynamiques comme le tir en rafale",
      "La base de l'écran occupe moins d'espace, ce qui offre au joueur plus de place pour ses mouvements de jeu",
      "Un meilleur ajustement de la hauteur et de l'inclinaison procure une plus grande flexibilité selon vos préférences personnelles",
      "Partagez votre configuration facilement grâce à XL Setting to Share ; menu rapide personnalisable",
      "La télécommande S-switch revisitée permet un scroll dans 4 directions ; nouveaux caches amovibles pour un assemblage rapide sans vis",
      "Technologies Color Vibrance et Black eQualizer pour améliorer la clarté visuelle et les paramètres de couleur personnalisés"
    ],
    "mainImage": "https://m.media-amazon.com/images/I/31TDgxC7SoL._SL500_.jpg",
    "variantImages": [],
    "price": {
      "amount": 481.84,
      "currency": "EUR",
      "displayPrice": "481,84 €"
    },
    "rating": {
      "stars": 0,
      "count": 0
    },
    "url": "https://www.amazon.fr/dp/B08HJ2VCB7?tag=esportgear0f-21&linkCode=ogi&th=1&psc=1"
  },
  {
    "asin": "B000V7ARAU",
    "title": "SteelSeries QcK Heavy - Tapis de souris de jeu en tissu - Base en caoutchouc antidérapant extra-épaisse - Optimisé pour les capteurs de jeu - Taille L (450mm x 400mm x 6mm)",
    "description": [
      "Tissu microtissé exclusif QcK pour un contrôle maximal",
      "La base en caoutchouc antidérapante très épaisse élimine les mouvements indésirables",
      "Optimisé pour le suivi des mouvements à faible et à haute résolution",
      "Durable et lavable pour un nettoyage facile",
      "QcK est le premier choix des professionnels de l'e-sport depuis plus de 15 ans",
      "Remarque - Pour les capteurs optiques et laser"
    ],
    "mainImage": "https://m.media-amazon.com/images/I/21KKQ28z4FL._SL500_.jpg",
    "variantImages": [],
    "price": {
      "amount": 24.99,
      "currency": "EUR",
      "displayPrice": "24,99 €"
    },
    "rating": {
      "stars": 0,
      "count": 0
    },
    "url": "https://www.amazon.fr/dp/B000V7ARAU?tag=esportgear0f-21&linkCode=ogi&th=1&psc=1"
  }
]